import moment from 'moment'
import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { FacebookShareButton, EmailShareButton, TwitterShareButton } from 'react-share'
import ReactSlider from 'react-slider'

import './ConstructionUpdates.scss'
import MailIcon from '../../../assets/icons/MailIcon'
import ChainIcon from '../../../assets/icons/Chain'
import ConstructionSlider from './ConstructionSlider'
import { getCorrectLogoBrand } from '../../../utils/getCorrectLogoBrand'

const ConstructionUpdates = (data: any): JSX.Element => {
  const { pageData } = data
  const { property } = pageData.pageContext
  const date = property.constructionUpdates.date.split('/').join('/1/')
  const { brand } = property.details

  const logoBrand = getCorrectLogoBrand(brand)

  const [copied, setCopied] = useState(false)
  const url = `https://dev-khaleesi-alisi.whitecloak.com/brand/property/construction-updates`

  return (
    <div className="construction-updates-container">
      <div className="construction-updates-left-container">
        <ConstructionSlider images={property.constructionUpdates.images} />
      </div>
      <div className="construction-updates-right-container">
        <div className="right-container">
          <div className="brand-logo">
            <img src={logoBrand} alt="brand logo" style={{ width: '128px' }} />
          </div>
          <div className="name-logo" style={{ marginTop: '50px' }}>
            <img src={property.details.imageLogo} alt="name logo" />
          </div>

          <div className="construction-updates-title-wrapper">
            <h2>Construction Updates</h2>

            {property.details.brand !== 'avida' && (
              <div className="share-container">
                {copied ? (
                  <>
                    <div className="share-icon-container copied fade-in-bottom">
                      <span>Copied to clipboard!</span>
                      <div onClick={() => setCopied(false)}>
                        <i className="la la-close" />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="share-wrapper">
                    <FacebookShareButton url={url}>
                      <div className="icon-container">
                        <i className="la la-facebook" />
                      </div>
                    </FacebookShareButton>

                    <TwitterShareButton url={url}>
                      <div className="icon-container">
                        <i className="la la-twitter" />
                      </div>
                    </TwitterShareButton>

                    <EmailShareButton url={url}>
                      <div className="icon-container">
                        <MailIcon />
                      </div>
                    </EmailShareButton>

                    <CopyToClipboard text={url} onCopy={(): void => setCopied(true)}>
                      <div className="icon-container">
                        <ChainIcon />
                      </div>
                    </CopyToClipboard>
                  </div>
                )}
              </div>
            )}
          </div>

          {property.constructionUpdates.date !== '' && (
            <div className="date">
              <span>{`As of ${moment(date).format('MMMM YYYY')}`}</span>
            </div>
          )}

          <div className="description">
            <p>{property.constructionUpdates.description}</p>
          </div>

          {property.constructionUpdates &&
            property.constructionUpdates.milestone.map((ml: any) => {
              return (
                <div className="milestone">
                  <span>{ml.title}</span>
                  <ul>
                    <li>{ml.list}</li>
                  </ul>
                </div>
              )
            })}

          {property.details.brand !== 'avida' && (
            <div className="percentage">
              <span>Percentage of Completion</span>
              {property.constructionUpdates.unitsCompletion.map((unit: any, index: number) => {
                return (
                  <div className="unit-completion-container">
                    <span>{unit.name}</span>
                    <div className="percentage-container">
                      <span>{unit.percentage !== '' ? `${unit.percentage}%` : '0%'}</span>
                      <ReactSlider
                        className="range-container"
                        thumbClassName="range-thumb"
                        trackClassName="range-track"
                        min={1}
                        max={100}
                        defaultValue={[1, unit.percentage]}
                        pearling
                        disabled
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ConstructionUpdates
