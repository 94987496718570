import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../../JobOpenings/JobHeroBanner.scss'

const ConstructionSlider = (props: any): JSX.Element => {
  const [carousel, setCarousel] = useState<null | any>(null)
  const [slideIndex, setSlide] = useState<number | null>(null)

  const sliderData: any = props.images ? props.images.map((x: any) => ({ src: x, type: 'image' })) : []
  const settings = {
    className: 'job-hero-slider',
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoPlay: true,
    autoPlaySpeed: 2000,
    arrows: false,
    draggable: true,
    fade: true
  }

  const next = (): void => {
    if (carousel) {
      carousel.slickNext()
    }
  }

  const prev = (): void => {
    if (carousel) {
      carousel.slickPrev()
    }
  }

  const handleChange = (slide: number): void => {
    setSlide(slide)
    const currentSlide = sliderData[slide]

    const videos: any = document.querySelectorAll('.ytp-play-button')

    if (currentSlide.type === 'video') {
      let currentVideo: any = document.querySelector(`#career-video-${slide}`)
    } else {
      videos.forEach((video: any) => {
        video.click()
        alert('yes!')
        // video.pause()
      })
    }
  }

  return (
    <>
      <Slider
        ref={(c) => setCarousel(c)}
        {...settings}
        afterChange={(data) => {
          handleChange(data)
        }}
      >
        {sliderData &&
          sliderData?.map((item: any, index: number) => {
            return (
              <div key={index}>
                <div className="job-hero-slide">
                  {item.type === 'image' && <img src={item.src} className="image-slide" />}

                  {item.type === 'video' && (
                    <div>
                      <ReactPlayer url={item.src} loop={true} playing={slideIndex === index ? true : false} />
                    </div>
                  )}

                  {/* {item.type === 'video' && (
                    <video className="image-slide careers-video" id={`career-video-${index}`} controls loop>
                      <source src={video} type="video/mp4" />
                    </video>
                  )} */}
                </div>
              </div>
            )
          })}
      </Slider>
      {sliderData.length !== 0 ? (
        <div className="job-slider-arrows">
          <div onClick={prev}>
            <i className="la la-angle-left" />
          </div>
          <div onClick={next}>
            <i className="la la-angle-right" />
          </div>
        </div>
      ) : null}
    </>
  )
}

export default ConstructionSlider
