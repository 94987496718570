import React, { ReactElement } from 'react'
import './Property.scss'
import PropertyLayout from '../../components/Layout/PropertyLayout'
import PropertyBaseMenu from '../../components/PropertyBaseMenu/PropertyBaseMenu'
import ConstructionUpdates from '../../components/PropertyDetailPage/ConstructionUpdates'

const PropertyUpdates = (data: any) => {
  const pageData = data
  return (
    <PropertyLayout active={5} pageData={pageData}>
      <PropertyBaseMenu pageData={pageData} />
      <ConstructionUpdates pageData={pageData} />
    </PropertyLayout>
  )
}

export default PropertyUpdates
